import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sftsk/Sites/boonconcom/src/components/podcasts-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Intro`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/saftsaak"
      }}>{`@saftsaak`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/metzelfetz"
      }}>{`@metzelfetz`}</a></p>
    <h3>{`Apologetic promises`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=fRh_vgS2dFE"
      }}>{`Sorry`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Candide"
      }}>{`Optimism`}</a></p>
    <h3>{`State of the Union`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=re9YorsrUa4"
      }}>{`Toilet revelation`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.positive.news"
      }}>{`Positive news`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/European_debt_crisis"
      }}>{`Euro crisis`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.bbc.com/news/world-europe-34131911"
      }}>{`Refugee crisis`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.theguardian.com/world/2016/jan/25/french-police-foiled-paris-terror-attack-bernard-cazeneuve"
      }}>{`Terrorist attacks`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.economist.com/blogs/graphicdetail/2016/01/graphics-britain-s-referendum-eu-membership"
      }}>{`Brexit`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.wikiwand.com/en/Russian_military_intervention_in_Ukraine_(2014%E2%80%93present)"
      }}>{`Ukraine war`}</a>{` – Rise of populists: Front National, UKIP, FPÖ, Fidesz, PiS – `}<a parentName="p" {...{
        "href": "https://petitions.whitehouse.gov/petition/secure-resources-and-funding-and-begin-construction-death-star-2016"
      }}>{`Death Star`}</a>{` – `}<a parentName="p" {...{
        "href": "http://europa.eu/about-eu/institutions-bodies/infographics/abc_eu_institutions_large_en.jpg"
      }}>{`EU institutions`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.euractiv.com/section/justice-home-affairs/news/brussels-unveils-controversial-plan-for-eu-border-force/"
      }}>{`EU vs Member States`}</a>{` – European new sources: `}<a parentName="p" {...{
        "href": "https://euobserver.com/"
      }}>{`euobserver`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.politico.eu/"
      }}>{`Politico EU`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.europeanpublicaffairs.eu/"
      }}>{`European Public Affairs`}</a></p>
    <h3>{`Worktopics`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.getharvest.com/"
      }}>{`Budgeting and time tracking with Harvest`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.getharvest.com/forecast"
      }}>{`Scheduling with Forecast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://trello.com/"
      }}>{`Lists of to do cards with Trello`}</a></p>
    <h3>{`The Golden Bridge to overseas`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.woothemes.com/woocommerce/"
      }}>{`WooCommerce for Wordpress`}</a>{` – `}<a parentName="p" {...{
        "href": "https://wooconf.com/"
      }}>{`WooConf 2016`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.torggler.bz/de/"
      }}>{`Online store example`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=WOPBoC3v8Z8"
      }}>{`Texas`}</a>{` – WooConf 2016 discount code: WOOCONFBOONCON – `}<a parentName="p" {...{
        "href": "https://2016.finland.wordcamp.org/"
      }}>{`WordCamp Finland 2016`}</a></p>
    <h3>{`Zika is in the air`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.who.int/mediacentre/factsheets/zika/en/"
      }}>{`Zika virus`}</a>{` – `}<a parentName="p" {...{
        "href": "http://ecdc.europa.eu/en/healthtopics/zika_virus_infection/zika-outbreak/Pages/epidemiological-situation.aspx"
      }}>{`Zika epidemic`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Guillain%E2%80%93Barr%C3%A9_syndrome"
      }}>{`Guillain–Barré Syndrom`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Myelitis"
      }}>{`Myelitis`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Encephalitis"
      }}>{`Encephalitis`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Microcephaly"
      }}>{`Microcephaly`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.bbc.co.uk/programmes/p03j0k30"
      }}>{`BBC The Inquiry: Why Don’t We Eradicate Mosquitoes?`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Aedes_albopictus"
      }}>{`Tiger mosquito`}</a></p>
    <h3>{`When it's cold outside`}</h3>
    <span style={{
      "fontWeight": "400"
    }}>
  [Beach volleyball](http://www.biitsi.fi/) – [Escape room
  Exite](http://www.exite.fi/en/) –
  [Lazer-haunted-house-themed-minigolf](http://www.hohtogolf.fi/helsinki/) –
  [Disc golf](https://www.wikiwand.com/de/Discgolf) – [Strap-on
  spikes](http://www.amazon.com/Yaktrax-Pro-Traction-Cleats-Snow/dp/B001CZJBKC)
    </span>
    <h3>{`Outro`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/podcast"
      }}>{`booncon.com/podcast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/booncon"
      }}>{`@booncon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      